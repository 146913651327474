$green: #0D381F;

.footer {
  padding: 40px 0 40px 0;

  .footer_links ul {
    text-align: center;
    a {
      list-style: none;
      display: inline-block;
      padding-right: 30px;
      cursor: pointer;
      color: $green;
    }

    li {
      list-style: none;
      display: inline-block;
      padding-right: 30px;
      cursor: pointer;
      color: $green;
    }
  }
}
.footer_desc {
  text-align: center;
  p {
    color: #999999;
  }
}

@media screen and (max-width: 768px) {
  .footer_desc p {
    text-align: center;
    float: none;
    width: 100% !important;
  }
}
