$green: #0D381F;
$darkblue: #173142;
.biznes_stoku {
  .row {
    margin-right: 0;
  }

  .sidebar {
    .biznes_menu ul {
      li.active2 a {
        color: $darkblue;
        text-decoration: none;
      }

      li.active a {
        color: #eefff9;
        text-decoration: none;
      }

      li.active1 a {
        color: #eefff9;
        text-decoration: none;
      }
    }
  }
}

.biznes_stoku {
  background-color: #fff;
  display: block;
  padding-top: 57px;

  .menaxhimi_stokut {
    padding-top: 57px;
    padding-bottom: 40px;
  }

  .button-group {
    text-align: right;

    .grid-button {
      padding: 2px;
      width: 5%;
      //margin-left: 1rem;
      border-radius: 5px;
      background-color: $green;
      margin-left: 1rem;
      font-size: 1.3rem;
      margin-right: 0px;
    }
  }

  .stoku_title {
    display: block;
    text-align: center;
    padding-bottom: 40px;

    h1 {
      float: left;
      letter-spacing: 0px;
      color: $green;
      opacity: 1;
      font-size: 27px;
      font: normal normal bold 'Raleway';
      font-weight: bold;
    }

    button {
      margin-left: 27%;
      text-align: center;
      background-color: $green;
      color: #ffffff;
      border-radius: 40px;
      border: none;
      width: 160px;
      height: 35px;
      padding: 5px 0;
      margin-right: 74px;
      // margin-left: 0px;
      // float: right;
      outline: none;

    }
  }

  a img {
    width: 120px;
    height: 80px;
    float: right;
    // display: inline-block;
    margin-top: -20px;
  }

  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $green;
      color: $green;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $green;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $green;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }


  // PRODUKTET
  .stoku {
    display: block;
  }

  .stok_produktet {
    width: 350px;
    height: auto;
    background: #f2f2f2;
    border-radius: 10px;
    padding: 15px;
    display: inline-block;
    margin: 0 20px 15px 0;

    .produkt_title {
      display: block;

      p {
        display: inline-block;
        color: #11141a;
        font: normal normal bold 'Raleway';
        font-weight: bold;
        letter-spacing: 0.58px;
        text-transform: capitalize;
        // font-size: 14px;
        opacity: 1;
      }

      img {
        width: 20px;
        height: 20px;
        float: right;
        // display: inline-block;
      }
    }

    .produkt_description {
      display: block;

      p {
        display: inline-block;
        padding: 12px 50px 0 0;
        margin-bottom: 0;
        text-align: left;
        font: normal normal normal 'Lato';
        font-size: 12px;
        letter-spacing: 0px;
        color: #7f8fa4;
        opacity: 1;
      }
    }

    .produkt_values {
      display: block;

      p {
        display: inline-block;
        text-align: left;
        font: normal normal bold 'Lato';
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0px;
        color: #354052;
        opacity: 1;
      }

      .sasia {
        padding-right: 60px;
      }

      .data {
        padding-right: 45px;
      }
    }
  }

}


@media screen and (max-width: 640px) {

  .biznes_stoku {
    //border: 3px solid red;

    .button-group {
      text-align: right;

      .grid-button {
        padding: 2px;
        width: 20% !important;
        //margin-left: 1rem;
        border-radius: 5px;
        background-color: $green;
        margin-left: 1rem;
        font-size: 1.3rem;
        margin-right: 0px;
      }
    }

    .stoku {

      //border: 3px solid red;
      .ListProducts {
        //border: 3px solid red;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        padding: 10px;
        //width: 100%;
        border-radius: 10px;
      }


      .stok_produktet {
        width: 100% !important;
        padding: unset;
      }
    }
  }
}