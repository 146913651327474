
$gray: #eeeeee;
$blue: #2aced7;
$darkblue: #173142;



.ListProducts {
  background-color: #f2f2f2 !important;
  //margin: 0.5rem 1rem 0rem 1rem;
  width: 100% ;
  margin-left: 0px !important;
  //margin-bottom: 2rem;

  .picture-location {
    //padding: 1rem;
    //margin-top: 1.1rem;
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 5px;

  }

  .img-container {
    display: inline-block;
    position: absolute;
    width: 30%;
    right: -10%;
    top: -5%;
    overflow: hidden;
    vertical-align: center;

    .actions {
      margin-left: 10px;
    }

  }

  img {
    //text-align: center;
    width: 18px;
    height: 18px;
  }



  .search-button {
    display: block;
    background-color: #2aced7;
    border: none;
  }

  .pagination {
    width: 100%;
  }


  .pagination {
    width: 100%;
  }

  .validation-text {
    color: maroon;
    font-weight: 600;
  }

  .paginationBttns {
    margin-top: 2rem;
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $blue;
      color: $blue;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $blue;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $blue;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }

  .actions {
    margin-top: 0;
    width: 20px;
    margin-left: 20px;
    height: 20px;
    //float: right;
    //margin-top: 0px;
    float: none ;
  }



  .validation-text {
    color: maroon;
    font-weight: 600;
  }

  .fjeshil-table {
    display: none !important;
  }

  .listimi-transactions {
    background: #f2f2f2;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 10px;
  }

}

@media only screen and (max-width: 600px) {
  .ListProducts {
    //border: 3px solid green !important;
    //margin-top: 1rem;
      margin-left: 0px !important;

    .search-button {
      display: block;
      background-color: #2aced7;
      border: none;
    }


    .first-date {
      margin-bottom: 0.5rem;
    }

    .last-date {
      margin-bottom: 0.5rem;
    }
  }
}



