$gray: #eeeeee;
$green: #0D381F;
$darkblue: #173142;
.shto_produkt {
  background: #fff;
  .row {
    margin-right: 0;
  }
  .sidebar {
    padding: 0;
    .biznes_menu {
      .active a {
        color: $darkblue;
        text-decoration: none;
      }
      .active1 a {
        color: #eefff9;
        text-decoration: none;
      }
      .active2 a {
        color: #eefff9;
        text-decoration: none;
      }
    }
  }
}



// Logo
.shp_logo {
  padding-top: 20px;
  background: $gray;
  img {
    width: 120px;
    height: 80px;
    float: right;
  }
}
// Form
.shp_form {
  background: $gray;
}


.shp_groupform {
  padding-left: 100px;
  padding-top: 70px;
  width: 60%;
  form {
    text-align: center;
    input:disabled, select:disabled, textarea:disabled {
      background: unset;
      // border: unset;




    }

    input {
      border-bottom: 2px solid #9b9b9b;
      border-radius: 0;
      margin-bottom: 10px;
      background: transparent;



      .form-control {
        outline: none;
        border: none;
        background-color: transparent !important;



      }
    }
    .active--class {
      display: flex;
      //justify-content: flex-end;

      .active--input {
        justify-content: center;
      }
    }


    label {
      width: 100%;
      outline: none;
      border: none;
      background-color: transparent !important;
      select {
        background-color: transparent !important;
      }
      textarea {
        width: 100%;
        background-color: transparent !important;
        outline: none;
      }
    }



    button {
      width: 200px;
      height: 40px;
      border-radius: 40px;
      background-color: $green;
      color: #eefff9;
      text-align: center;
      margin-top: 20px;
      border: none;
      outline: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .fjeshil-container {
    padding-top: 10% !important;
    .shp_groupform {
      width: 100% !important;
      padding: unset !important;
      padding: 20px !important;
    }
    .biznes_title {
      a {
        margin-left: 60px !important;
      }
    }
    .card {
      width: 100% !important;
    }
  }
}