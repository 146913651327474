$green: #0D381F;
$darkblue: #173142;

.admin-emails {


  .user-image {
    //display: flex;
    //text-align: left;
    //margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 150px;
    height: 150px;
    border-radius: 90%;
    display: inline;
    object-fit: cover;
    border: 5px solid #0D381F;
  }

  .input-mail {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  padding-top: 5%;

  .emails {
    padding-top: 5%;

    li {
      list-style: none;
      padding: 10px 40px;
      background-color: $green;
      color: white;
      margin-bottom: 5px;
      border-radius: 10px;
    }
  }

  button {
    margin-bottom: 5px;
    width: 120px;
    border: unset;
    border-radius: 10px;
    background-color: $green;
    color: white;
  }

  form {
    width: 100%;

    textarea {
      width: 100%;
      border: unset;
      border: 1px $green solid;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 5px;
    }
  }
}