$gray: #fff;
$green: #0D381F;


.login {
  height: 100vh;
  text-align: center;
  background: $gray;
  padding-top: 100px;
}

.logo_login img {
  object-fit: cover;
  width: 300px;
  height: 300px;
  padding-bottom: 40px;
}

.error-msg {
  font-weight: 600;
  color: maroon;
}

.login_formgroup {
  text-align: center;
  width: 40%;
  margin: auto;

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    border-bottom: 2px solid #9b9b9b;


    input {
      background-color: transparent;
      margin-left: -15px;
      padding-left: 30px;
      border: none;

      :hover {
        border: 1px solid #9b9b9b;
      }
    }

    .icon {
      padding: 10px 0;
      color: #9b9b9b;
      font-size: 20px;
      text-align: center;

      input .form-control {
        outline: none;
        border: none;
        background-color: transparent !important;
      }
    }
  }


  .forgot-password {
    //border: 3px solid red;
    text-align: left;

    //margin-right: 2rem;
    .forgot-password-part {
      //margin-right: 5rem;
      color: $green;
      margin-top: 1.2rem;
    }
  }

  .btn_signin {
    float: right;
    padding-top: 15px;
    padding-bottom: 100px;

    button {
      width: 200px;
      height: 40px;
      background: $green 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 40px;
      opacity: 1;
      // border-color: #2aced7;
      border: none;
      font: normal normal 'Roboto';
      font-size: 15px;
      letter-spacing: 0.32px;
      color: $gray;
      opacity: 1;
      outline: none;
    }
  }
}

//
@media screen and (max-width: 768px) {
  .login_formgroup {
    width: 60%;
    //border: 3px solid red;
  }

  img {
    //border: 3px solid red;
  }

  .login_formgroup {
    //border: 3px solid green;
  }

  .fjeshil-content {
    width: 100% !important;
  }

  .fjeshil-sidebar {
    display: none;
  }

  .fjeshil-logo {
    display: none;
  }

  .login_formgroup {
    //border: 3px solid red;

    .btn_signin {
      float: none;

      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .login_formgroup {
    width: 80%;
    height: auto !important;
    //border: 3px solid red;
  }

  img {
    width: 70% !important;
  }
  .footer {
    display: none;
  }

  .login_formgroup {
    //border: 3px solid green;
  }

  .fjeshil-content {
    width: 100% !important;
  }

  .fjeshil-sidebar {
    display: none;
  }

  .fjeshil-logo {
    display: none;
  }
  .logo_login {
    width: 90%;
    margin: auto;
  }

  .login_formgroup {
    //border: 3px solid red;
    width: 90%;

    .btn_signin {
      float: none;
      padding-bottom: 5px;

      button {
        width: 100%;
      }
    }
  }
}

