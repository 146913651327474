$gray: #eeeeee;
$green: #0D381F;
$darkblue: #173142;

.shfrytezuesi {
  position: relative;
  margin: unset;


  .text-password {
    color: maroon;
    font-size: 16px;
    font-weight: 500;
  }


  .upload-imazhi {
    position: fixed;
    z-index: 2;
    width: 66%;
    top: 16%;
    right: 0;
    background: white;
    border-radius: 45px 0 0 45px;
    // padding-left: 10%;
    // padding-bottom: 1%;
    padding: 10px;


    form {

      input {
        float: left;
        width: 65%;
        padding: unset;
        border: 3px solid $green;
        border-radius: 20px;
        margin-right: 5%;
      }

      button {
        width: 30%;
        background: $green;
        border: 3px $green solid;
        // border: unset;
        color: white;
        border-radius: 20px;
        float: right;

        &:disabled {
          background: $darkblue;
          border: 3px $darkblue solid;
        }
      }
    }
  }

  .ndrysho-imazhin {
    // display: none;
    position: absolute;
    top: 9%;
    // left: -5%;
    filter: unset;
    color: white;
    background: $green;
    z-index: 1;
    padding: 2px 25px;
    border: unset;
    border-radius: 45px;
    transition: ease-in-out .3s;

    &:hover {
      // border: 3px white solid;
      filter: drop-shadow(0 0 0.35rem grey);
      // transform: translate(50px, 100px);
      margin-left: 20px;

    }
  }

  .user-image-container {
    position: absolute;
    top: -3%;
    left: -12%;
    transition: filter 1s ease-in-out;
    padding-bottom: 5%;
    // padding-left: 10%;
    border-radius: 50%;
    overflow: hidden;
    // margin-bottom: 5%;
    //width: 150px;
    height: 150px;

    border: 5px white solid;

    .picture-location {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    &:hover {
      filter: drop-shadow(0 0 0.75rem grey);
      // transform: translate(50px, 100px);
    }

    .user-image {
      display: block;
      background: whitesmoke;
      padding: unset;
      width: inherit;
      height: inherit;
    }
  }

  .titulli {
    position: absolute;
    top: 15%;
    left: 26.5%;
    // color:darkblue;
    font-size: calc(1vw + 30px);
    width: auto;
    z-index: 1;
  }

  background: #fff;

  .row {
    margin-right: 0;
  }

  label {
    text-align: left;
  }

  .sidebar {
    padding: 0;

    .biznes_menu {
      .active a {
        color: $darkblue;
        text-decoration: none;
      }

      .active1 a {
        color: #eefff9;
        text-decoration: none;
      }

      .active2 a {
        color: #eefff9;
        text-decoration: none;
      }
    }
  }

  .shp_groupform {
    //   padding-left: 100px;
    padding: unset !important;
    padding-top: 70px;
    width: 100%;

    form {
      text-align: center;
      // input:disabled {
      //     // background: unset;
      //     // border-radius: unset;
      //     border: unset;
      //     // color: black;
      //     ::placeholder {
      //         // color: black;
      //     }
      //     ::-ms-value {
      //         // color: black;
      //     }
      // }
      input {
        border-bottom: 2px solid #9b9b9b;
        border-radius: 0;
        margin-bottom: 10px;
        background: transparent;

        .form-control {
          outline: none;
          border: none;
          background-color: transparent !important;
        }
      }

      label {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent !important;

        select {
          background-color: transparent !important;
        }

        textarea {
          width: 100%;
          background-color: transparent !important;
          outline: none;
        }
      }

      button {
        width: 200px;
        height: 40px;
        border-radius: 40px;
        background-color: $green;
        color: #eefff9;
        text-align: center;
        margin-top: 20px;
        border: none;
        outline: none;

        &:disabled {
          background: $darkblue;
        }
      }
    }
  }

  // Form
  .shp_form {
    background: transparent;
    padding-top: 18%;
    // padding-bottom: 10%;
  }
}


@media only screen and (max-width: 600px) {

  .shfrytezuesi {
    margin-top: 2rem;


    .shp_form {
      //border: 3px solid red;
      margin-top: 4rem;
    }

    .user-image-container {
      margin-top: 3rem;
      top: 0px;
      left: 0px;

      .picture-location {
        height: 100% !important;
        width: 100% !important;
      }
    }


    .upload-imazhi {
      ////display: flex;
      ////margin-bottom: 5rem;
      position: absolute;
      width: 80%;
      margin-top: 4rem;
      right: 40px;

      form {

        input {
          margin-bottom: 10px;
          width: 100%;
          //border: 3px solid red;
          display: block;
        }

        button {
          width: 100%;
          display: block;
          //border: 3px solid red;
        }
      }
    }

    h4 {
      margin-top: 10rem;
    }

    .ndrysho-imazhin {
      margin-top: 4.5rem;
    }

  }
}