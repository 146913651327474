$green: #0D381F;

.Statistikat {

  h1 {
    letter-spacing: 0px;
    color: $green;
    opacity: 1;
    font-size: 27px;
    font: normal normal bold "Raleway";
    font-weight: bold;
  }

  .input-container {
    width: 100%;
    display: flex;
  }

  h3 {
    //float: left;
    letter-spacing: 0px;
    color: #173142;
    opacity: 1;
    font-size: 22px;
    font: normal normal bold "Raleway";
    font-weight: bold;
  }

  .search-button {
    background-color: $green;
    border: 0;
  }
}

@media only screen and (max-width: 600px) {
  .Statistikat {
    //border: 3px solid red;


    .input-container {
      margin: auto;
      flex-wrap: wrap;

      .first-date {
        width: 100%;
        margin-bottom: 1rem;
      }

      .search-button {
        width: 100%;
      }
    }
  }
}