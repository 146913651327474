@media only screen and (max-width: 600px) {
  .Statistikat {
    //border: 3px solid red;


    .input-container {
      margin: auto;
      flex-wrap: wrap;

      .first-date {
        width: 100%;
        margin-bottom: 1rem;
      }

      .search-button {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}