
$gray: #eeeeee;
$green: #0D381F;
$darkblue: #173142;


.BulkData {
  background-color: #fff !important;
  margin: 0rem 0 5rem 0;
  padding-top: 50px;

  h1 {
    //float: left;
    letter-spacing: 0px;
    color: $green;
    opacity: 1;
    font-size: 27px;
    font: normal normal bold "Raleway";
    font-weight: bold;
  }

  .search-button {
    display: block;
    background-color: $green;
    border: none;
  }

  .pagination {
    width: 100%;
  }

  .upload {
    margin-top: 15px;
  }

  .pagination {
    width: 100%;
  }

  .validation-text {
    color: maroon;
    font-weight: 600;
  }

  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $green;
      color: $green;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $green;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $green;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }


  .validation-text {
    color: maroon;
    font-weight: 600;
  }

  .fjeshil-table {
    display: none !important;
  }

  .listimi-transactions {
    background: #f2f2f2;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 10px;
  }

}

@media only screen and (max-width: 600px) {
  .Transactions {
    margin-top: 1rem;

    .search-button {
      display: block;
      background-color: $green;
      border: none;
    }


    .first-date {
      margin-bottom: 0.5rem;
    }

    .last-date {
      margin-bottom: 0.5rem;
    }
  }
}
