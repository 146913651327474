$gray: #eeeeee;
$green: #0D381F;
.regjistro_biznesin {
  background: #fff;
  // padding: 76px;
  // height: 104vh;
  height: auto;
  z-index: 99;
  padding-top: 6%;

}


.regjistro_form {

  .text-area-form {
    // border: 3px solid red;
  }


  .validation-field {
    color: maroon;
    font-weight: bold !important;
    font-size: 20;
  }


  textarea {
    word-wrap: normal;
    // width: 340px;
    padding: 5px 15px;
    background: #eeeeee 0% 0% no-repeat padding-box;
    //border-bottom: #707070;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    color: #707070;
    font-size: 12.5px;
  }

  .text-password {
    color: maroon;
    font-size: 16px;
    font-weight: 500;
  }


  p {
    float: left;
    padding: 40px 0 0 5px;
    color: #504d4d;
  }

  form {
    // float: left;
    padding: 10px 30px;

    .fiscal-nr {
      font-size: 14px;
      font-weight: 600;
      color: maroon;
    }

    label {
      float: left;
      width: 100%;
    }


    select,
    input {
      word-wrap: normal;
      // width: 340px;
      padding: 5px 15px;
      background: #eeeeee 0% 0% no-repeat padding-box;
      /* border-bottom: #707070; */
      border: none;
      width: 100%;

      .select_mbiemri {
        float: right;
      }
    }

    input#agree {
      width: auto;
      float: left;
      margin-top: 5px;
    }

    label#kushtet {
      width: auto;
      margin-left: 5px;
    }

    select:hover,
    input:hover {
      outline: 0.10000000149011612px solid #707070;
    }

    input.select_emri {
      float: left;
      width: 47%;
    }

    input.select_mbiemri {
      float: right;
      width: 47%;
    }

    select.rb_select,
    input.rb_select,
    input.select_emri,
    input.select_mbiemri {
      border-bottom: 1px solid #707070;
      color: #707070;
      font-size: 12.5px;
    }

    // }
  }

  .regjistrohu_textarea {
    float: left;
    padding-top: 40px;

    label textarea {
      width: 100%;
      // height: 164px;
      background-color: #ffffff;
      color: #999999;
      padding: 15px;
      font-size: 12.5px;
      border-top: none;
      border-left: none;
      border-right: none;
      outline: none;
      border-bottom: 1px solid #707070;
    }
  }

  .terms_condition {
    // padding: 0px 50px;
    text-align: left;

    .check_ {
      width: 100%;
      float: left;
    }

    button {
      // margin-left: 20px;
      width: 200px;
      height: 40px;
      background: $green 0% 0% no-repeat padding-box;
      border-radius: 40px;
      border: none;
      font: normal normal 'Roboto';
      font-size: 15px;
      letter-spacing: 0.32px;
      color: #eefff9;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .regjistro_biznesin {
    height: 100%;
    padding: 0 40px;

    .logo {
      padding-top: 55px;

      img {
        width: 100px;
        height: 70px;
      }
    }

    h1 {
      // float: left;
      text-align: center;
      font-size: 35px;
      padding-left: 0;
      padding-top: 40px;
    }

    .regjistro_form {
      padding: 0;

      .form_left,
      .form_right {
        max-width: 100% !important;
        margin: auto;
      }
    }

    .terms_condition button {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 534px) {
  .regjistro_biznesin {
    .logo {
      padding-top: 73px;

      img {
        width: 100px;
        height: 60px;
      }
    }

    h1 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 425px) {
  .regjistro_biznesin {
    .logo {
      padding-top: 63px;

      img {
        width: 100px;
        height: 60px;
      }
    }

    h1 {
      font-size: 20px;
      float: left;
    }

    .terms_condition {
      padding: 0px 50px;
      text-align: left;

      button {
        margin-left: 0px;
        width: 170px;
        height: 40px;
        background: $green 0% 0% no-repeat padding-box;
        border-radius: 40px;
        border: none;
        font: normal normal 'Roboto';
        font-size: 15px;
        letter-spacing: 0.32px;
        color: #eefff9;
      }

      label {
        font-size: 12.7px;
      }
    }

    .regjistro_form {
      p {
        padding: 30px 0 0 5px;
      }

      .form_left {
        padding-right: 0;
      }

      .form_right {
        padding-left: 10px;
        padding-right: 0;
      }

      form {
        padding: 0;
      }

      .regjistrohu_textarea {
        padding: 30px 0 10px 10px;
      }
    }

    .terms_condition {
      padding: 0 0 0 10px;
    }
  }
}
