
$gray: #eeeeee;
$blue: #2aced7;
$darkblue: #173142;
$green: #0D381F;
* {  }
.fjeshil-sidebar {
    position: fixed;
    width: 25%;
    top:0;
    bottom: 0;
    background: $green !important;
    height: 100% !important;
    overflow-y: auto;
}
.notify-msg {
    background-color: green;
    color: white;
    width: auto;
    z-index: 9999;
    border-radius: 10px;
    position: fixed;
    top: 3%;
    right: 5%;
    padding: 10px;
    button {
        background: white;
        border: unset;
        border-radius: 40px;
        padding: 5px 10px;
        margin-left: 20px;
        transition: .3s ease-in;
        &:hover {
            background-color: #bababa;
            font-size: 22px; 
            padding: 0px 7px;
        }
    }
  }

  .notify-msg-bad {
    background-color: red;
    color: white;
    width: auto;
    z-index: 9999;
    border-radius: 10px;
    position: fixed;
    top: 3%;
    right: 5%;
    padding: 10px;
    button {
        background: white;
        border: unset;
        border-radius: 40px;
        padding: 5px 10px;
        margin-left: 20px;
        transition: .3s ease-in;
        &:hover {
            background-color: #bababa;
            font-size: 22px; 
            padding: 0px 7px;
        }
    }
  }

.fjeshil-content {
    width: 75%;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    height: auto;
    padding: unset;
    background: #fff !important;

    .fjeshil-container {

        padding-top: 3%;
        padding-left: 2%;
        padding-right: 2%;
        position: relative;
        width: 100%;


    }
}

.fjeshil-logo {
    position: absolute;
    right: 3%;
    top: 3%;
    z-index: 2;
    img {
        width: 170px;
        height: auto;
    }
}
