$blue: #2aced7;
$darkblue: #173142;
$green: #0D381F;

.mobile_menu {
  display: none;
  position: fixed;
  background: $green;
  top: 0;
  left: 0;
  right: 0;

  height: 80px;
  // margin-bottom: -50px !important;
  z-index: 9999999999;
  overflow:hidden;
  .mobile-logo {
    width: 100px !important;
  }
  button {
    padding: 25px 10px;
    background: transparent;
    border: unset;
    color: white;
    .fa {
      float: right;
      font-size: 30px;
    }
  }
  ul {
    list-style: none;
    // text-decoration: unset;
    height: auto;
      // overflow-y: scroll !important;

    li, .shkyqu {
      padding: 20px 10px;
      svg {margin-right: 10px; color: white;}
      a { color: white;}
      div {
        margin-bottom: 15px;
        svg {margin-right: 10px;}
        a { color: white;}
        .subnav {
          width: 30%;
          float: right;
        }
      }
      .dropdown {
        padding-left: 50px;
      }
    }
  }
}
.biznes_sidebar {
  background-color: $green;
  height: 100%;

  .biznes_menu ul {
    list-style: none;
    color: #ffffff;
    padding: 190px 20px 0 40px;
    .highliteme {color:black !important;}
    li.shkyqu {
      padding-left: 5px;
      font-size: 1.5em;
      span{
    
        font-size: 16px;
      }
    }
    li {
      text-align: left;
      font: normal normal normal'Roboto';
      font-size: 16px;
      letter-spacing: 0.3px;
      padding-bottom: 5px;
      color: #eefff9;
      opacity: 1;
      cursor: pointer;



      a {
        color: #ffffff;
        list-style: none;
        text-decoration: none;

        //transition-delay: 1s;
        &:hover {
          color: grey;
          //transition-delay: 1s;
        }
        &:focus {
          color: grey;
          //transition-delay: 1s;
        }
        &:active {
          color: grey;
        }
        span {
          padding: 5px;
          margin: 0;
          font-size: 24px;
          text-align: start;
        }
      }
    }
    .subnav {
      float: right;
      font-size: 24px;
    }
    .dropdown {
      padding-left: 3rem;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #f5f5f5;
      font-size: 17px;

      :hover {
        width: 100%;
        //background-color: $darkblue;
        color: #000;
        border-radius: 10px;
        padding: 5px;
      }
    }
  }
  .signout {
    color: #ffffff;
    padding-left: 70px;
    padding-bottom: 7%;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    a {
      color: white;
      text-decoration: none;
      :hover {
        color: grey;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .mobile_menu {
    display: inline;
    // height: auto;
    // overflow-y: auto;
    .mobile-data {
      // border: 3px red solid;
      overflow-y: scroll !important;
      position: fixed;
      top: 8%;
      left: 0;
      right: 0;
      bottom: 0;
      background: $green;
    }
  }
}