$green: #0D381F;
$darkblue: #173142;


.shtetet-lista {


  $green: #0D381F;
  $darkblue: #173142;

  .biznes_stoku {
    .row {
      margin-right: 0;
    }

    .sidebar {
      .biznes_menu ul {
        li.active2 a {
          color: $darkblue;
          text-decoration: none;
        }

        li.active a {
          color: #eefff9;
          text-decoration: none;
        }

        li.active1 a {
          color: #eefff9;
          text-decoration: none;
        }
      }
    }
  }

  .biznes_stoku {
    background-color: #f1f1f1;
    display: block;

    .menaxhimi_stokut {
      padding-top: 57px;
      padding-bottom: 40px;
    }

    .stoku_title {
      display: block;
      text-align: center;
      padding-bottom: 40px;

      h1 {
        float: left;
        letter-spacing: 0px;
        color: $green;
        opacity: 1;
        font-size: 27px;
        font: normal normal bold 'Raleway';
        font-weight: bold;
      }

      button {
        margin-left: 27%;
        text-align: center;
        background-color: $green;
        color: #ffffff;
        border-radius: 40px;
        border: none;
        width: 160px;
        height: 35px;
        padding: 5px 0;
        margin-right: 74px;
        // margin-left: 0px;
        // float: right;
        outline: none;

      }
    }

    a img {
      width: 120px;
      height: 80px;
      float: right;
      // display: inline-block;
      margin-top: -20px;
    }

    // PRODUKTET
    .stoku {
      display: block;
    }

    .stok_produktet {
      width: 350px;
      height: 120px;
      background: #f2f2f2;
      border-radius: 10px;
      padding: 15px;
      display: inline-block;
      margin: 0 20px 15px 0;

      .produkt_title {
        display: block;

        p {
          display: inline-block;
          //color: #11141a;
          font: normal normal bold 'Raleway';
          font-weight: bold;
          letter-spacing: 0.58px;
          text-transform: capitalize;
          // font-size: 14px;
          opacity: 1;
        }

        img {
          width: 20px;
          height: 20px;
          float: right;
          // display: inline-block;
        }
      }

      .produkt_description {
        display: block;

        p {
          display: inline-block;
          padding: 12px 50px 0 0;
          margin-bottom: 0;
          text-align: left;
          font: normal normal normal 'Lato';
          font-size: 12px;
          letter-spacing: 0px;
          color: #7f8fa4;
          opacity: 1;
        }
      }

      .produkt_values {
        display: block;

        p {
          display: inline-block;
          text-align: left;
          font: normal normal bold 'Lato';
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0px;
          color: #354052;
          opacity: 1;
        }

        .sasia {
          padding-right: 60px;
        }

        .data {
          padding-right: 45px;
        }
      }
    }
  }


  background-color: #f2f2f2;

  .row {
    margin-right: 0;
  }

  .sidebar {
    .biznes_menu ul {
      li.active a {
        color: $darkblue;
        text-decoration: none;
      }

      li.active1 a {
        color: #eefff9;
        text-decoration: none;
      }

      li.active2 a {
        color: #eefff9;
        text-decoration: none;
      }

      li.active3 a {
        color: #eefff9;
        text-decoration: none;
      }

      li.active4 a {
        color: #eefff9;
        text-decoration: none;
      }
    }
  }

  // .produktet {
  //   max-height: 400px;
  //   overflow-y: scroll;
  // }
  .biznes_title {
    display: block;
    text-align: center;
    padding-top: 57px;
    padding-bottom: 40px;

    h1 {
      float: left;
      letter-spacing: 0px;
      color: $green;
      opacity: 1;
      font-size: 27px;
      font: normal normal bold 'Raleway';
      font-weight: bold;
    }

    button {
      text-align: center;
      background-color: $green;
      color: #f1f1f1;
      border-radius: 40px;
      border: none;
      width: 160px;
      height: 35px;
      padding: 5px 0;
      margin-right: 74px;
      outline: none;
      margin-left: 17%;
      // margin-left: 0px;
      // float: right;
    }

    a img {
      width: 120px;
      height: 80px;
      float: right;
      // display: inline-block;
      margin-top: -20px;
    }
  }

  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $green;
      color: $green;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $green;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $green;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }
}


// LISTA PRODUKTEVE *****************
.lista__produkteve {
  width: 100%;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  // margin-left: 25px;
}

.product-name {
  margin-bottom: 15px;
}

.category-name {
  margin-bottom: 15px;
}

.lista_produkteve {
  background: white;
  color: black;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
  font-size: 12px;
  margin-bottom: 15px;

  li {
    //padding-top: 0.4rem;
    display: inline-flex;
    flex: 1;
    margin: 10px 15px;

    p {
      padding-right: 60px;
      margin: auto;
      text-align: left;
      font: normal normal normal 'Roboto';
      letter-spacing: 0.24px;
      color: #999999;
      opacity: 1;
    }

    .cmimi {
      color: #7dad3f;
    }

    img {
      text-align: center;
      width: 18px;
      height: 18px;
    }
  }


  .picture-location {
    //padding: 1rem;
    margin-top: -1rem;
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }


}

.listimi-shtetet {
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-left: 15px;
  // margin: auto;
  margin-bottom: 10px;
  width: 72%;
  // float: left;
  padding: 10px 40px;
  transition: .3s ease-in-out;

  &:hover {
    filter: drop-shadow(0 .3rem 0.55rem #7f8fa4);
  }

  .picture-location {
    //padding: 1rem;
    //margin-top: 1.1rem;
    height: 100px;
    width: 100px;
    object-fit: cover;


    border-radius: 50%;

  }

  .img-container {
    display: inline-block;
    position: absolute;
    width: 30%;
    right: -10%;
    top: -5%;
    overflow: hidden;
    vertical-align: center;

    .actions {
      margin-left: 10px;
    }

  }

  img {
    //text-align: center;
    width: 18px;
    height: 18px;

  }


  .delete-product-img {
    margin-top: 1rem;
  }

  .category--name {
    margin-left: 0.1rem;
  }

  .status--name {
    padding-top: 2%;
    margin-left: 0.1rem;
    font-family: Courier;
    font-weight: 600;
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .listimi-shtetet {

    width: 100%;
    margin-left: 3rem;

    a {

      //border: 3px solid red;
      img {
        width: 200% !important;
        margin-left: 0rem !important;
      }
    }
  }
}


