$gray: #eeeeee;
$green: #0D381F;
$darkblue: #173142;
.store-image {

  position: relative;
  // margin-right: -15vw;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  padding: unset;

  .catImage {
    margin-left: 35%;
    width: auto;
  }


  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

.leaflet-container {
  height: 30vw;
  width: 70vw;
  //border: 3px solid red;
}

.image-form {
  margin-top: -2.5%;
  padding: 1.2%;
  background: white;
  border-radius: 0 0 20px 20px;

  button {
    float: right;

    &:disabled {
      background: $darkblue;
    }
  }

  input {
    padding: 6px 15px;
    width: 100%;
    height: 40px;
    border-radius: 40px;
    border: 2px solid $green;
  }
}

.image-form button {
  width: 200px;
  height: 40px;
  border-radius: 40px;
  background-color: $green;
  color: #eefff9;
  text-align: center;
  // margin-top: 20px;
  border: none;
  outline: none;
}

.storeimage-btn {
  position: relative;
  top: -25px;
  z-index: 5;
  opacity: .8;
  border: unset;
  border-radius: unset;
  background: $gray;
  width: 100%;
}


.biznis-faqja {
  // border: 3px solid red;

  .text-area-form {
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 2px solid #9b9b9b;
    //height: calc(1.5em + .75rem + 2px);;
  }

  .text-area-form:disabled, .text-area-form:disabled {
    background: unset;
    border-radius: unset;
    border: unset;
    color: black;

    ::placeholder {
      color: black;
    }

    ::-ms-value {
      color: black;
    }
  }

  // z-index: 5;
  margin-top: -5%;

  .fjeshil-container {
    position: relative;
    width: 100%;
    padding: unset;

    .user-image {
      display: block;
      background: whitesmoke;
      padding-top: 18%;
      padding-left: 10%;
      border-radius: 50%;
      margin-bottom: 5%;
      width: 150px;
      height: 150px;
    }
  }

  .titulli {
    position: absolute;
    top: 5%;
    left: 26.5%;
    // color:darkblue;
    font-size: calc(1vw + 30px);
    width: auto;
    z-index: 1;
  }

  background: #f2f2f2;

  .row {
    margin-right: 0;
  }

  label {
    text-align: left;
  }

  .sidebar {
    padding: 0;
    background: $green;

    .biznes_menu {
      .active a {
        color: $darkblue;
        text-decoration: none;
      }

      .active1 a {
        color: #eefff9;
        text-decoration: none;
      }

      .active2 a {
        color: #eefff9;
        text-decoration: none;
      }
    }
  }


  // Form
  .shp_form {
    background: $gray;
  }


  .shp_groupform {
    //   padding-left: 100px;
    padding: unset !important;
    padding-top: 70px;
    width: 100%;

    .fiscal-nr {
      color: maroon;
      font-weight: 550;
      text-align: right;
    }


    form {
      //border: 3px solid red;
      text-align: center;

      input:disabled, select:disabled {
        background: unset;
        border-radius: unset;
        border: unset;
        color: black;

        ::placeholder {
          color: black;
        }

        ::-ms-value {
          color: black;
        }
      }

      .input__button {
        border: none;
        border-right: 1px solid #ced4da;;
        border-top: 1px solid #ced4da;;
        border-left: 1px solid #ced4da;;
        border-bottom: 2px solid #9b9b9b;;;
        height: calc(1.5em + .75rem + 2px);
      }

      select {
        border-bottom: 2px solid #9b9b9b;
        border-radius: 0;
        margin-bottom: 10px;
        background: transparent;
        border: none;
        border-right: 1px solid #ced4da;;
        border-top: 1px solid #ced4da;;
        border-left: 1px solid #ced4da;;
        border-bottom: 2px solid #9b9b9b;;;
        height: calc(1.5em + .75rem + 2px);

        .form-control {

          outline: none;
          border: none;
          background-color: transparent !important;
        }
      }

      label {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent !important;

        select {
          background-color: transparent !important;
        }

        textarea {
          width: 100%;
          background-color: transparent !important;
          outline: none;
        }
      }

      button {
        width: 200px;
        height: 40px;
        border-radius: 40px;
        background-color: $green;
        color: #eefff9;
        text-align: center;
        margin-top: 20px;
        border: none;
        outline: none;

        &:disabled {
          background: $darkblue;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .fjeshil-container {

    .picture-location {
      object-fit: contain !important;
      width: 150% !important;
      //height: 200% !important;
    }

    .add-shop {
      margin-left: 8rem !important;
    }
    input {
      //border: 3px solid red;
    }
  }
}