$green: #0D381F;
$white: #fff;
.dashbordi {

  background: $white;
  display: block;
  .row {
    margin-right: 0;
  }

  .dashboard {
    .dashboard_title {
      display: block;
      text-align: center;
      padding: 57px 0px;

      h1 {
        float: left;
        letter-spacing: 0px;
        color: $green;
        opacity: 1;
        font-size: 27px;
        font: normal normal bold 'Raleway';
        font-weight: bold;
        padding-bottom: 30px;
      }

      a img {
        width: 120px;
        height: 80px;
        float: right;
        // display: inline-block;
        margin-top: -20px;
      }
    }
    .charts {
      margin-top: -20px;

      .teardhurat_total {
        width: 200px;
        height: 100px;
        background: #f2f2f2;
        padding: 15px;
        border-radius: 10px;
        display: inline-block;
        margin-right: 20px;
        hr {
          margin: 0;
        }
        p {
          margin: 5px 0;
          text-align: left;
          font: 'Roboto';
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.32px;
          color: #707070;
          opacity: 1;
        }
        .price {
          text-align: left;
          font: 'Montserrat';
          font-weight: normal;
          font-size: 20px;
          letter-spacing: 0.48px;
          color: #000000;
          opacity: 1;
        }
      }
      .porosite {
        width: 160px;
        height: 85px;
        padding: 11px;
        border-radius: 10px;
        background: #f2f2f2;
        display: inline-block;
        margin-right: 20px;

        hr {
          margin: 0;
        }

        p {
          margin: 0;
          padding: 3px;
          text-align: left;
          font: 'Roboto';
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.32px;
          color: #707070;
          opacity: 1;
        }
        .price {
          text-align: left;
          font: normal normal 'Roboto';
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0px;
          color: #173142;
          opacity: 1;
        }
      }
    }
  }

  // SUBMENU
  .submenu {
    display: block;
    padding-top: 20px;
    p {
      display: inline-block;
      margin-right: 40px;
      font: normal normal bold 'Roboto';
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0px;
      opacity: 1;
      cursor: pointer;
    }


    hr {
      margin: 0;
      width: 70%;
    }
  }



  .te_reja {
    color: #173142;
  }
  .te_skaduara {
    color: #bbc5d5;
  }
  .te_realizuara {
    color: #bbc5d5;
  }
}
.kerkesat {
  display: block;
}
.dashbordi {
.kerkesa_tereja {
  display: inline-block;
  width: 235px;
  height: auto;
  background: #f2f2f2;
  padding: 10px;
  // margin: 20px 20px 20px 0;
  border-radius: 10px;

  .emri_produktit {
    p {
      margin: 0;
      text-align: left;
    }
    .produkt {
      color: #11141a;
      font: normal normal bold 'Raleway';
      font-weight: bold;
      letter-spacing: 0.58px;
      text-transform: capitalize;
      font-size: 14px;
      opacity: 1;
    }



    .person {
      text-align: left;
      font: normal normal normal 'Lato';
      font-size: 11px;
      letter-spacing: 0px;
      color: #7f8fa4;
      opacity: 1;
      padding-bottom: 30px;
    }
  }



  .data {
    p {
      margin: 0;
    }
    .data_text {
      text-align: left;
      font: normal normal normal 'Lato';
      font-size: 12px;
      letter-spacing: 0px;
      color: #7f8fa4;
      opacity: 1;
    }

    .data_ {
      text-align: left;
      font: normal normal normal 'Lato';
      letter-spacing: 0px;
      font-size: 14px;
      color: #354052;
      opacity: 1;
    }
  }
  hr {
    margin: 10px 0;
  }
  .prano_anulo {
    float: left;
  }
  a {
    text-decoration: none;

    .prano {
      margin: 0 70px 0 0;
      text-align: left;
      font: normal normal bold 'Lato';
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0px;
      color: #7dad3f;
      opacity: 1;
    }
    .anulo {
      text-align: left;
      font: normal normal bold 'Lato';
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0px;
      color: #bbc5d5;
      opacity: 1;
    }
  }
}

.op2 {
  .kerkesa_tereja {
    margin: 0 20px 0 0;
  }
}


  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns  {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid  $green;
      color: $green;
      cursor: pointer;
    }
  }

  .paginationBttns  {
    a {
      &:hover {
        color: white;
        background-color: $green;
      }
    }
  }

  .paginationActive  {
    a {
      color: white;
      background-color:  $green;
    }
  }

  .paginationDisabled  {
    a {
      display: none;
    }
  }


.statistikat1 {

  width: 25%;

  float: right;
  margin-top: 0px;
  p {
    padding-left: 60px;
    text-align: left;
    font: normal normal bold 'Roboto';
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    color: #173142;
    opacity: 1;
  }
}
.statistikat {
  //float: right;
  width: 300px;
  height: auto;
  background-color: #ffffff;
  margin-right: 0px;
  border-radius: 10px;
  text-align: center;
  img {
    text-align: center;
    width: 260px;
    height: 260px;
    border-radius: 10px;
  }
}
}

@media screen and (max-width: 640px) {
.kerkesat { 
  width: 100%;
    .kerkesa_tereja {
      padding-top: 15px;
        width: 100%;}
    .data, .emri_produktit {
      width: 100%;
      padding-left: 10%;
    }
    .prano_anulo {
      width: 100%;
      text-align: center;
      margin: auto;
      button { width: 45%;}
    }
}
.charts {
  width: 100%;
  .teardhurat_total {
    width: 100% !important;
    text-align: center !important;
  }
  .porosite {
    width: 100% !important;
    text-align: center !important;
  }
}
.TeReja {
  // background-color: red;
  width: 100%;
  .statistikat1 {
    width: 100%;
  }

}
}